<template>
  <div>
    <div>
      <div class="table-padding">
        <div class="filter-sorting-container">
          <k-search-bar-section
            placeholder="Search By Collection Id, Name, Status..."
            hide-sort-option
            hide-filter-option
            hide-column-edit-option
            @on-search="updateSearch"
          />
          <multiple-filter
            class="ms-2"
            :filter-fields="filterFields"
            @on-apply-filter="onApplyFilter"
          />
          <k-sorting
            class="ms-auto"
            hide-column-edit-option
            :demo-checkbox-label="`${ showDemoData ? 'Hide':'Show'} Demo Collections`"
            show-demo-checkbox
            :sort-items="sortFields"
            @update-sort="updateSort"
            @update-sort-direction="updateSortDirection"
            @update-demo-filter="updateDemoFilter"
          />
        </div>
        <div
          v-if="loading"
          class="spinner-body"
        >
          <b-spinner />
        </div>
        <div
          v-else
          class="table-responsive"
        >
          <!-- Table section -->
          <p-table
            :fields="fields"
            :items="items"
          >
            <template #empty>
              <p
                class="
                    h2
                    d-flex
                    table-empty-message
                    justify-content-center
                    align-items-center
                    gap-2
                  "
              >
                <b-icon
                  icon="exclamation-circle"
                  scale="1"
                />
                <span> No items found </span>
              </p>
            </template>
            <template #cell(name)="data">
              <b-link
                class="text-decoration-none text-nowrap"
                @click="editCollection(data.item)"
              >
                {{ data.item.name }}
              </b-link>
            </template>
            <template #cell(entityName)="data">
              <b-link
                class="text-decoration-none text-nowrap"
                :to="{path: `/user-details/${data.item.userId}`}"
              >
                {{ data.item.entityName }}
              </b-link>
            </template>
            <template #cell(departments)="data">
              <div
                :id="`collection_departments_${data.index}`"
                class="table-url-item text-truncate"
              >
                {{ data.item.departments.join(', ') }}
              </div>
              <b-tooltip
                :target="`collection_departments_${data.index}`"
                triggers="hover"
                placement="bottom"
                custom-class="cursor-pointer"
              >
                <div
                  class="text-center"
                >
                  {{ data.item.departments.join(', ') }}
                </div>
              </b-tooltip>
            </template>
            <template #cell(status)="data">
              <span
                class="text-uppercase collection-status-variants"
                :class="data.item.status"
              >{{ collectionStatus[data.item.status] }}</span>
            </template>
            <template #cell(type)="data">
              <span class="text-capitalize text-nowrap">{{ collectionType[data.item.type] || '' }}</span>
            </template>
            <template #cell(endDate)="data">
              <div v-if="data.item.endDate">
                <span
                  v-if="getRemainingDays(data.item.endDate) <= 3 && data.item.status !== COLLECTION_STATUS.EXPIRED.VALUE"
                  class="collection-deadline-variants less-than-3"
                >
                  {{ formattedDate(data.item.endDate) }}
                </span>
                <span
                  v-else-if="getRemainingDays(data.item.endDate) <= 7 && data.item.status !== COLLECTION_STATUS.EXPIRED.VALUE"
                  class="collection-deadline-variants less-than-7"
                >
                  {{ formattedDate(data.item.endDate) }}
                </span>
                <span v-else>
                  {{ formattedDate(data.item.endDate) }}
                </span>
              </div>
            </template>
            <template #cell(startDate)="data">
              {{ formattedDate(data.item.startDate) }}
            </template>
            <template #cell(deliveryStartDate)="data">
              {{ formattedDate(data.item.deliveryStartDate) }}
            </template>
            <template #cell(deliveryEndDate)="data">
              {{ formattedDate(data.item.deliveryEndDate) }}
            </template>
            <template #cell(fromDate)="data">
              {{ formattedDate(data.item.fromDate) }}
            </template>
            <template #cell(toDate)="data">
              {{ formattedDate(data.item.toDate) }}
            </template>
            <template #cell(isPremium)="data">
              <div>
                <span>
                  <label
                    class="custom-checkbox-container"
                  >
                    <input
                      :checked="data.item.isPremium"
                      type="checkbox"
                      disabled
                    >
                    <span class="checkmark" />
                  </label>
                </span>
              </div>
            </template>
            <template #cell(minimumOrderValue)="data">
              {{ formatCurrency(data.item.minimumOrderValue || 0, data.item.currency) }}
            </template>
            <template #cell(productsFile)="data">
              <div
                v-if="data.item.productsFile"
                class="d-flex flex-row align-items-center"
              >
                <b-link
                  class="
                  d-inline-block
                  text-truncate
                  table-url-item
                  text-decoration-none
                "
                  :href="data.item.productsFile"
                  target="_blank"
                >
                  {{ data.item.productsFile }}
                </b-link>
                <span
                  class="ms-1 cursor-pointer"
                  @click="onClickCopy(data.item.productsFile)"
                ><copy-text-icon /></span>
              </div>
            </template>
          </p-table>
        </div>
        <k-table-pagination
          :total-items="itemsCount"
          @emit-current-page="updateCurrentPage"
          @emit-per-page="updatePerPage"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { formattedDate, getRemainingDays } from '@/@core/utils/format'
import { apiToastError } from '@/@core/utils/toast'
import CopyTextIcon from '@/assets/images/svg/CopyTextIcon.vue'
import { copyToClipboard } from '@/common-utils'
import { KSearchAndSortMixin } from '@/mixins/k-searbar-section.mixin'
import { PTablePaginationMixin } from '@/mixins/p-table.mixin'
import store from '@/store'
import { GET_COLLECTION_LIST } from '@/store/modules/collection.module'
import {
  CLEAR_STATE, SET_COMPONENT, SET_COMPONENT_CONTENT, SET_FORM_ACTIONS, SET_SUB_TITLE, SET_TITLE, TOGGLE_SIDEBAR,
} from '@/store/modules/sidebar.module'
import { getCollectionFields } from '@/table-fields-constants'
import {
  KSearchBarSection,
  KTablePagination,
  PTable,
} from '@kingpin-global/kingpin-ui'
import { constants, utils } from '@kingpin-global/kingpin-utils-frontend'
import { BLink, VBTooltip } from 'bootstrap-vue'
import CollectionEdit from './CollectionEdit.vue'

const { COLLECTION_STATUS, COLLECTION_TYPE } = constants
const { formatCurrency } = utils

export default {
  name: 'CollectionList',
  components: {
    PTable,
    KTablePagination,
    KSearchBarSection,
    BLink,
    CopyTextIcon,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  mixins: [KSearchAndSortMixin, PTablePaginationMixin],
  data() {
    return {
      loaded: false,
      fields: getCollectionFields(),
      items: [],
      formattedDate,
      componentContent: {},
      collectionType: {},
      collectionStatus: {},
      getRemainingDays,
      COLLECTION_STATUS,
      showDemoData: false,
      formatCurrency,
    }
  },
  computed: {
    loading() {
      return !(this.items.length || this.loaded)
    },
    sortFields() {
      const sortFields = ['name', 'status', 'startDate', 'endDate', 'type']
      return this.fields.filter(field => sortFields.includes(field.key))
    },
  },
  created() {
    this.mapConstants()
    this.initPageContent()
  },
  unmounted() {
    store.commit(CLEAR_STATE)
  },
  methods: {
    mapConstants() {
      Object.values(COLLECTION_TYPE).forEach(({ LABEL, TEXT }) => {
        this.collectionType[LABEL] = TEXT
      })
      Object.values(COLLECTION_STATUS).forEach(({ LABEL, VALUE }) => {
        this.collectionStatus[VALUE] = LABEL
      })
    },
    initPageContent() {
      if (this.items.length > 0) {
        this.updateFilteredItemAndPaginationParams(this.items)
      }
      const queryParams = {
        page: this.currentPage - 1,
        limit: this.perPage,
        search: this.searchText,
        isDemo: this.showDemoData,
      }
      if (this.sortBy) {
        queryParams.sortBy = this.sortBy
        queryParams.asc = !this.isDesc
      }
      if (Object.values(this.filterQuery).length) {
        queryParams.filter = JSON.stringify(this.filterQuery)
      }
      this.loadCollections(queryParams)
    },
    loadCollections(queryParams) {
      store.dispatch(GET_COLLECTION_LIST, queryParams)
        .then(res => {
          this.items = res.data.data.collections
          this.items.forEach(item => {
            item.fromDate = item.expectedDeliveryWindow?.fromDate || ''
            item.toDate = item.expectedDeliveryWindow?.toDate || ''
          })
          this.itemsCount = res.data.data.count
          this.updateFilteredItemAndPaginationParams(this.items)
          this.setFilterFields(res.data.data.filter, this.fields)
          this.loaded = true
        })
        .catch(err => {
          apiToastError(err)
          this.loaded = false
        })
    },

    onClickCopy(text) {
      copyToClipboard(text, 'Products File Link was saved to your clipboard successfully!')
    },
    editCollection(collection) {
      this.componentContent = {
        collection,
      }
      store.commit(CLEAR_STATE)
      store.commit(SET_TITLE, 'Edit Collection')
      this.$store.commit(SET_SUB_TITLE, collection.name)
      store.commit(SET_COMPONENT, CollectionEdit)
      store.commit(SET_COMPONENT_CONTENT, this.componentContent)
      store.commit(SET_FORM_ACTIONS, this.editFormActions)
      this.toggleSidebar()
    },
    toggleSidebar() {
      store.commit(TOGGLE_SIDEBAR)
    },
    editFormActions(actionType) {
      switch (actionType) {
        case 'submit':
          this.initPageContent()
          this.toggleSidebar()
          break
        case 'cancel':
          this.toggleSidebar()
          break
        default:
          break
      }
    },
  },
}
</script>
