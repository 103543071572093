<template>
  <div class="create-wallet-padding">
    <div class="form-block collection-max-height">
      <k-form-group
        label-content="Collection Id"
      >
        <k-form-input
          v-model="collection._id"
          type="text"
          disabled
        />
      </k-form-group>
      <k-form-group
        class="mt-2"
        label-content="Status"
      >
        <b-dropdown
          class="w-100 k-search-dropdown "
          variant="none"
          toggle-class="drop-caret-icon text-color"
          :text="collectionStatusConst[collectionStatus] || 'Select status'"
        >
          <b-dropdown-item
            v-for="status of Object.values(COLLECTION_STATUS)"
            :key="status.VALUE"
            :active="collection.status === status.VALUE"
            @click="updateStatus(status.VALUE)"
          >
            <span class="text-capitalize">{{ status.LABEL }} </span>
          </b-dropdown-item>
        </b-dropdown>
      </k-form-group>
      <k-form-group
        class="mt-2"
        label-content="Publish Date"
      >
        <k-form-input
          v-model="collection.startDate"
          type="date"
        />
      </k-form-group>
      <k-form-group
        class="mt-2"
        label-content="Order deadline"
      >
        <k-form-input
          v-model="collection.endDate"
          type="date"
        />
      </k-form-group>
      <!-- <k-form-group
        class="mt-2"
        label-content="Logo"
      >
        <b-link
          class="file-link"
          :href="collection.logo"
        >
          <file-item
            :file-name="collection.logo ? 'Collection\'s logo' : 'No logo available'"
            hide-delete
            :hide-download="!collection.logo"
          />
        </b-link>
      </k-form-group>-->
      <k-form-group
        class="mt-2"
        label-content="Products Link"
      >
        <b-link
          class="file-link"
          :href="collection.productsFile"
        >
          <file-item
            :file-name="collection.productsFile ? 'Collection\'s products file' : 'No products file available'"
            hide-delete
            :hide-download="!collection.productsFile"
          />
        </b-link>
      </k-form-group>
    </div>
    <div class="d-flex align-items-center mt-3">
      <k-button
        variant="info"
        type="submit"
        block
        :disabled="loading"
        @click="updateCollection(collection)"
      >
        SAVE CHANGES
        <loader-icon v-if="loading" />
      </k-button>
      <k-button
        variant="outline-info"
        class="ms-4"
        block
        @click.stop="$emit('dynamic-emits','cancel')"
      >
        CANCEL
      </k-button>
    </div>
  </div>
</template>
<script>
import { formatDateForInput, formattedDate } from '@/@core/utils/format'
import { apiToastErrorV2, apiToastSuccessV2 } from '@/@core/utils/toast'
import FileItem from '@/components/file-item/FileItem.vue'
import store from '@/store'
import { UPDATE_COLLECTION } from '@/store/modules/collection.module'
import { KButton, KFormGroup, KFormInput } from '@kingpin-global/kingpin-ui'
import { constants } from '@kingpin-global/kingpin-utils-frontend'
import {
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'
import { LoaderIcon } from 'vue-feather-icons'

const { COLLECTION_STATUS, COLLECTION_TYPE } = constants

export default {
  components: {
    BDropdown,
    BDropdownItem,
    LoaderIcon,
    KButton,
    KFormGroup,
    KFormInput,
    FileItem,
  },
  props: {
    componentContent: {
      type: Object,
      default() {
        return null
      },
    },
  },
  data() {
    return {
      collection: {},
      loading: false,
      COLLECTION_STATUS,
      COLLECTION_TYPE,
      collectionRemoveMessage: '',
      canDeleteCollection: false,
      isSaving: false,
      collectionStatus: '',
      collectionType: null,
      collectionStatusConst: {},
    }
  },
  emits: ['dynamic-emits'],
  mounted() {
    if (this.componentContent && this.componentContent.collection) {
      this.collection = JSON.parse(JSON.stringify(this.componentContent.collection))
      this.collectionStatus = this.collection.status || ''
      this.collectionType = Object.values(COLLECTION_TYPE).find(_ => _.LABEL === this.collection.type)
      if (this.collection.endDate) {
        this.collection.endDate = formatDateForInput(formattedDate(this.collection.endDate))
      }
      if (this.collection.startDate) {
        this.collection.startDate = formatDateForInput(formattedDate(this.collection.startDate))
      }
    }
  },
  created() {
    Object.values(COLLECTION_STATUS).forEach(({ LABEL, VALUE }) => {
      this.collectionStatusConst[VALUE] = LABEL
    })
  },
  methods: {
    setCollectionType(collectionTypeOption) {
      this.collectionType = collectionTypeOption
      this.collection.type = collectionTypeOption.LABEL
    },
    updateStatus(status) {
      this.collectionStatus = status
      this.collection.status = status
    },
    updateCollection(collection) {
      this.loading = true
      const {
        startDate,
        endDate,
      } = collection
      const payload = {}
      if (startDate) {
        payload.startDate = new Date(startDate)
      }
      if (endDate) {
        payload.endDate = new Date(endDate)
      }
      if (collection.status) {
        payload.status = collection.status
      }
      store.dispatch(UPDATE_COLLECTION, { collectionId: collection._id, payload })
        .then(({ data }) => {
          apiToastSuccessV2(data.message)
          this.loading = false
          this.$emit('dynamic-emits', 'submit')
        })
        .catch(err => {
          this.loading = false
          apiToastErrorV2(err)
        })
    },

  },
}
</script>
<style lang="scss">
    .file-link {
        text-decoration: none;
        .file-item{
            margin: 0;
        }
    }
</style>
